/* global Image, document, Office */
import { isExcel, isPowerPoint, isWord } from "../../Util/officeAppsAPI";
import insertImagesIntoPowerPointDocument, {
  findMinimumImageWidth as findMinimumImageWidthInPowerPointDocument,
} from "./Apps/Powerpoint";
import {
  insertImagesIntoDocument as insertImagesIntoWordDocument,
  findMinimumImageWidth as findMinimumImageWidthInWordDocument,
} from "./Apps/Word";
import {
  insertImagesIntoDocument as insertImagesIntoExcelDocument,
  findMinimumImageWidth as findMinimumImageWidthInExcelDocument,
} from "./Apps/Excel";
import { Base64Image, GridDimensions } from "./ImageInsertion.Interfaces";
import { truncateOrgName } from "./ImageInsertionHelpers";
import { imageNotFoundPlaceHolderConfig } from "../../config/addinConfig";

export async function getImageAsBase64String(
  url: string,
  minWidth: number,
  orgName: string,
  isImageAvailable: boolean,
  isCompressImagesSelected: boolean
): Promise<Base64Image> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Allow some scope for manual upscaling
        minWidth *= 3;
        // Ensure canvas is large enough to fit the image to prevent poor upscaling
        const imageWidth = Math.max(minWidth, img.width);
        const imageHeight = img.height * (imageWidth / img.width);
        canvas.height = imageHeight;
        canvas.width = imageWidth;
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        const imageFound = isImageAvailable;

        if (imageFound) {
          if (isCompressImagesSelected) {
            const dataURL = canvas.toDataURL(`image/png`);
            const base64Data = dataURL.replace(/^data:image\/(png|jpg|jpeg|svg);base64,/, "");
            resolve({
              data: base64Data,
              width: imageWidth,
              height: imageHeight,
              orgName,
              imageFound,
            });
          } else {
            let blob = await fetch(url).then((r) => r.blob());
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
              resolve({
                data:
                  typeof reader.result === "string"
                    ? reader.result.replace(/^data:image\/(png|jpeg|svg\+xml);base64,/, "")
                    : "",
                width: imageWidth,
                height: imageHeight,
                orgName,
                imageFound,
              });
            };
            reader.onerror = function (error) {
              reject(error);
            };
          }
        } else {
          // If image not found, create a canvas with orgName and convert it to base64
          const textCanvas = document.createElement("canvas");
          const textCtx = textCanvas.getContext("2d");
          if (textCtx) {
            textCanvas.width = imageWidth;
            textCanvas.height = imageHeight / 2;
            textCtx.fillStyle = imageNotFoundPlaceHolderConfig.backgroundColor; // Background color
            textCtx.fillRect(0, 0, textCanvas.width, textCanvas.height);
            textCtx.font = imageNotFoundPlaceHolderConfig.font;
            textCtx.fillStyle = imageNotFoundPlaceHolderConfig.color; // text color
            textCtx.textBaseline = "middle";
            const availableWidth = imageWidth - 20;
            const availableHeight = Math.round(imageHeight / 2);
            const lineHeight = 40;

            const WrappedOrgName = truncateOrgName(orgName, availableWidth, textCtx, availableHeight, lineHeight);

            let y = (availableHeight - WrappedOrgName.totalTextHeight) / 2;
            // Ensure that y is at least the ascent of the first line
            const firstLineAscent = textCtx.measureText(WrappedOrgName.lines[0]).actualBoundingBoxAscent;
            y = Math.max(y, firstLineAscent);

            // Loop through each line in the 'lines' array
            for (const line of WrappedOrgName.lines) {
              // Calculate the x-coordinate to center the text horizontally
              const x = (imageWidth - textCtx.measureText(line).width) / 2;
              // Draw the line of text on the canvas
              textCtx.fillText(line, x, y);
              // Increase the y-coordinate for the next line, accounting for ascent and descent
              y += lineHeight - (firstLineAscent - textCtx.measureText(line).actualBoundingBoxAscent);
            }

            const textDataURL = textCanvas.toDataURL(`image/png`);
            const textBase64Data = textDataURL.replace(/^data:image\/(png|jpg|svg);base64,/, "");

            resolve({
              data: textBase64Data,
              width: imageWidth,
              height: imageHeight / 2,
              orgName: orgName,
              imageFound,
            });
          } else {
            reject("Could not get textCanvas context");
          }
        }
      } else {
        reject("Could not get canvas context");
      }
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = url;
  });
}

export async function findMinimumImageWidth(insertGridDimensions: GridDimensions): Promise<number> {
  if (isPowerPoint() && Office.context.requirements.isSetSupported("ImageCoercion", "1.1")) {
    return await findMinimumImageWidthInPowerPointDocument(insertGridDimensions);
  } else if (isWord() && Office.context.requirements.isSetSupported("WordApi", "1.1")) {
    return await findMinimumImageWidthInWordDocument();
  } else if (isExcel() && Office.context.requirements.isSetSupported("ExcelApi", "1.9")) {
    return await findMinimumImageWidthInExcelDocument();
  } else {
    throw new Error("Unsupported app. Please let us know the app you are using and report this to Williams Lea.");
  }
}

export async function insertImagesFromBase64Strings(
  base64Images: Base64Image[],
  insertGridDimensions: GridDimensions,
  alignment: string,
  isRetainShapeSelected: boolean,
  isDistributeHorizontallySelected:boolean,
) {
  if (isPowerPoint() && Office.context.requirements.isSetSupported("ImageCoercion", "1.1")) {
    await insertImagesIntoPowerPointDocument(
      base64Images,
      insertGridDimensions,
      alignment,
      isRetainShapeSelected,
      isDistributeHorizontallySelected
    );
  } else if (isWord() && Office.context.requirements.isSetSupported("WordApi", "1.1")) {
    await insertImagesIntoWordDocument(base64Images);
  } else if (isExcel() && Office.context.requirements.isSetSupported("ExcelApi", "1.9")) {
    await insertImagesIntoExcelDocument(base64Images);
  } else {
    throw new Error("Unsupported app. Please let us know the app you are using and report this to Williams Lea.");
  }
}
