export async function circleInsertion(
  isRetainShapeSelected: boolean,
  insertShape: any,
  cellPaddingPts: number,
  insertGridDimensions: any,
  base64Images: any,
  insertIntoOfficeDocumentAsync: any
) {
  // Non-modifiable variables
  const shapePadding = isRetainShapeSelected ? 10 : 0;
  const offsetLeft = insertShape.left + shapePadding;
  const offsetTop = insertShape.top + shapePadding;
  const totalShapeHeight =
    insertShape.height - insertShape.height * 0.2 - (cellPaddingPts * insertGridDimensions.rows - 1) - shapePadding;
  const maxCellHeight = totalShapeHeight / insertGridDimensions.rows;
  const tenPercentOfHeight = insertShape.height * 0.1;
  const fullRadius = insertShape.width / 2;
  const innerPadding = 3;

  // Modifiable variables
  let shapeTop = offsetTop + tenPercentOfHeight;
  let rowIndex = 0;
  let rowIndexCalc = 0;
  let totalRowsArea = 0;
  let newRadius = fullRadius - tenPercentOfHeight;
  let newRadiusCalc = fullRadius - tenPercentOfHeight;
  let base64ImagesInGrid = [];

  // Process all images to be used
  for (const base64Image of base64Images) {
    // Capture aspect ratio
    const AP = base64Image.height / base64Image.width;

    base64ImagesInGrid.push({
      width: base64Image.width, // width will be recalculated when we know how many items there are in row
      height: base64Image.height,
      ratio: AP,
      base64Image: base64Image,
    });
  }

  // Calculate total area used by rows by simulating all rows being inserted
  while (rowIndexCalc < insertGridDimensions.rows) {
    const chord = 2 * Math.sqrt(fullRadius * fullRadius - newRadiusCalc * newRadiusCalc);
    const shapeWidth = chord;

    // Collect total area here
    totalRowsArea += shapeWidth;

    // After row width is calculated, adjust values for next row
    if (insertGridDimensions.rows % 2 === 0 && rowIndexCalc + 1 === insertGridDimensions.rows / 2) {
      newRadiusCalc + 0;
    } else {
      if (rowIndexCalc >= Math.floor(insertGridDimensions.rows / 2)) {
        Math.abs((newRadiusCalc += maxCellHeight));
      } else {
        newRadiusCalc -= maxCellHeight;
      }
    }

    rowIndexCalc++;
  }

  // Make a copy of original number of images, to be used in percentage calculation later
  const imagesLength = base64ImagesInGrid.length;

  if (insertGridDimensions.rows === 2) {
    newRadius = fullRadius - insertShape.height / 2 + maxCellHeight + cellPaddingPts;
    shapeTop = offsetTop + insertShape.height / 2 - maxCellHeight - 2 * shapePadding;
  }

  // Apply following logic for each row
  while (rowIndex < insertGridDimensions.rows) {
    if (insertGridDimensions.rows === 1) {
      newRadius = fullRadius / 2 + cellPaddingPts;
      shapeTop = offsetTop + insertShape.height / 2 - maxCellHeight / 2 - cellPaddingPts;

      if (base64ImagesInGrid.length === 1) {
        newRadius = fullRadius / 2 + maxCellHeight * 0.15 + cellPaddingPts;
        shapeTop = offsetTop + insertShape.height / 2 - maxCellHeight / 2 - cellPaddingPts;
      }
    }

    // Work out row width and position
    const chord = 2 * Math.sqrt(fullRadius * fullRadius - newRadius * newRadius);
    const shapeWidth = chord;
    let shapeLeft = offsetLeft + fullRadius - shapePadding - chord / 2;

    // Work out percent row takes up relative to total
    const rowAreaPercentage = Math.round((shapeWidth / totalRowsArea) * 100);

    if (base64ImagesInGrid.length > 0) {
      // Find out how many images to use in row
      let imagesToUse = Math.round(imagesLength * (rowAreaPercentage / 100));

      if (rowIndex === insertGridDimensions.rows - 1) {
        imagesToUse += 1;
      }

      if (insertGridDimensions.rows === 2) {
        imagesToUse = Math.round(imagesLength / 2);
      }

      // Deal with the items a row at a time
      const itemsInRow = base64ImagesInGrid.splice(0, imagesToUse);
      const maxCellWidth = (shapeWidth - (innerPadding * itemsInRow.length - 1)) / itemsInRow.length;

      // Final image sizes are calculated here
      const itemsInRowAdjusted = itemsInRow.map((item: any) => {
        let imageWidth = maxCellWidth;
        let imageHeight = imageWidth * item.ratio;

        // If image clamped by height, then work out new width using aspect ratio
        if (item.height / item.width > maxCellHeight / item.width) {
          imageHeight = maxCellHeight;
          imageWidth = imageHeight / item.ratio;
        }

        // If image is larger than max cell width, clamp to max cell width
        if (imageWidth > maxCellWidth) {
          imageWidth = maxCellWidth;
        }

        return {
          width: imageWidth,
          height: imageHeight,
          base64Image: item.base64Image,
        };
      });

      // Each row has a different distribution of whitespace
      let totalWidthUsed = itemsInRowAdjusted.reduce((total: any, image: any) => total + image.width, 0);
      const totalWhitespaceAvailable = shapeWidth - totalWidthUsed;
      let horizontalWhitespaceGap = totalWhitespaceAvailable / (itemsInRowAdjusted.length - 1);

      let nextLeft = shapeLeft;

      if (itemsInRowAdjusted.length === 1) {
        nextLeft += totalWhitespaceAvailable / 2;
      }

      // Image insertion
      for (const base64ImageInGrid of itemsInRowAdjusted) {
        const options: Office.SetSelectedDataOptions = {
          coercionType: Office.CoercionType.Image,
          imageLeft: nextLeft,
          imageTop: shapeTop,
        };

        options.imageWidth = base64ImageInGrid.width;
        nextLeft += base64ImageInGrid.width + horizontalWhitespaceGap;

        // Vertically align images in row if they are clamped by with or rows = 1
        if (base64ImageInGrid.base64Image.height / base64ImageInGrid.base64Image.width < maxCellHeight / maxCellWidth) {
          const calculatedHeight =
            (maxCellWidth * base64ImageInGrid.base64Image.height) / base64ImageInGrid.base64Image.width;
          options.imageTop! += (maxCellHeight - calculatedHeight) / 2;
        }

        await insertIntoOfficeDocumentAsync(base64ImageInGrid.base64Image.data, options);
      }
    }

    // After insertion, adjust values for next row
    if (insertGridDimensions.rows % 2 === 0 && rowIndex + 1 === insertGridDimensions.rows / 2) {
      newRadius + 0;
    } else {
      if (rowIndex >= Math.floor(insertGridDimensions.rows / 2)) {
        Math.abs((newRadius += maxCellHeight));
      } else {
        newRadius -= maxCellHeight;
      }
    }

    if (insertGridDimensions.rows === 2) {
      shapeTop += maxCellHeight + 2 * shapePadding;
    } else {
      shapeTop += maxCellHeight + cellPaddingPts;
    }

    rowIndex++;
  }
}
