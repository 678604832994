import { PhotoResults, SearchFilters } from "../../../Components/ImageSearch/ImageSearch.Interfaces";
import { post } from "../apiClient";
import { nanoid } from "nanoid";

async function fetchPhotosViaSearchEngine(
  query: string,
  page: number,
  pageSize: number,
  searchFilters: SearchFilters
): Promise<PhotoResults> {
  if (query === "") {
    return {
      photos: [],
      missingOrgs: [],
      photoCount: 0,
      format: "",
      opacity: "",
      colour: "",
      isLatest: false,
      isBest: true,
      isAsc: true,
      sort: "",
      type: "logo",
      orgName: "",
      message: "",
      isImageAvailable: false,
      isAlias: false,
      status:"",
      theme:"",
    };
  }

  const response = await post("/search-engine", "wl-logostore-tester", {
    selectedItems: query,
    engineToUse: "google",
    page,
    pageSize: pageSize,
    format: searchFilters.format,
    opacity: searchFilters.opacity,
    colour: searchFilters.colour,
    isLatest: searchFilters.isLatest,
    isBest: searchFilters.isBest,
    isAsc: searchFilters.isAsc,
    sortColumn: searchFilters.sort,
    type: searchFilters.type,
    isAlias: searchFilters.isAlias,
    status:searchFilters.status,
    theme:searchFilters.theme,
  });

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null || json.data.logos == null) {
    return {
      missingOrgs: [],
      photos: [],
      photoCount: 0,
      format: "",
      opacity: "",
      colour: "",
      isLatest: false,
      isBest: true,
      isAsc: true,
      sort: "",
      type: "",
      orgName: "",
      message: "",
      isImageAvailable: false,
      isAlias: false,
      status:"",
      theme:"",
    };
  }

  const photos = json.data.images.map((logo: any) => ({
    id: nanoid(),
    previewUrl: logo.imageUrl,
    url: logo.imageUrl,
    width: 200,
    height: 200,
    searchSource: "searchEngine",
    opacity: logo.opacity,
    colour: logo.colour,
    isLatest: logo.isLatest,
    orgName: logo.orgName,
    isImageAvailable: logo.isImageAvailable,
  }));

  return {
    photos,
    photoCount: json.data.totalImageCount,
    format: json.data.format,
    opacity: json.data.opacity,
    colour: json.data.colour,
    isLatest: json.data.isLatest,
    isBest: json.data.isBest,
    sort: json.data.sort,
    type: json.data.type,
    isAsc: json.data.isAsc,
    orgName: json.data.orgName,
    message: json.message,
    missingOrgs: json.data.missingOrgs,
    isImageAvailable: json.data.isImageAvailable,
    isAlias: json.data.isAlias,
    status:json.data.status,
    theme:json.data.theme,
  };
}

export default fetchPhotosViaSearchEngine;
