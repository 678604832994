import { Auth } from "aws-amplify";

async function getSessionData() {
  const user = await Auth.currentSession();
  return user;
}

export const isUserLoggedIn = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export { getSessionData };
