import {
  IDropdownStyles,
  ISpinButtonStyles,
  mergeStyleSets,
  mergeStyles,
  ICheckboxStyles,
  IDialogContentStyleProps,
  IDialogStyles,
} from "@fluentui/react";

export const SpinnerStyles: Partial<ISpinButtonStyles> = {
  root: { display: "flex", gap: "50px" },
  labelWrapper: { width: "80px", marginLeft: "10px" },
  spinButtonWrapper: { width: "3rem" },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: { display: "flex", gap: "10px" },
  label: { width: "130px", marginLeft: "10px", marginTop: "1px" },
  dropdown: { width: "86px", height: "9%", marginTop: "4px", fontSize: "13px" },
  dropdownItem: { fontSize: "13px" },
  dropdownItemSelected: { fontSize: "13px" },
};

export const distributeCheckBoxStyles: Partial<ICheckboxStyles> = {
  label: {
    width: "20px",
    fontWeight: "600",
    fontSize: "10px",
    marginTop: "3px",
    marginLeft: "10px",
    marginBottom: "3px",
  },
  checkbox: {
    marginTop: "13px",
    fontSize: "16px",
    width: "18px",
    height: "18px",
    marginLeft: "91px",
  },
};
export const retainShapeCheckBoxStyles: Partial<ICheckboxStyles> = {
  root: { display: "flex", gap: "50px" },
  label: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "10px",
    marginLeft: "10px",
  },
  checkbox: {
    marginTop: "1px",
    fontSize: "16px",
    marginLeft: "84px",
    width: "18px",
    height: "18px",
  },
};
export const compressImagesCheckBoxStyles: Partial<ICheckboxStyles> = {
  root: { display: "flex", gap: "50px" },
  label: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "10px",
    marginLeft: "10px",
  },
  checkbox: {
    marginTop: "1px",
    marginLeft: "55px",
    fontSize: "16px",
    width: "18px",
    height: "18px",
  },
};
