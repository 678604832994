import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import {
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Checkbox,
} from "@fluentui/react";
import { PhotoGrid } from "../../Components/ImageSearch/PhotoGrid/PhotoGrid";
import { SearchAutoComplete } from "../../Components/SearchAutoComplete/SearchAutoComplete";
import { getClassNames } from "./Styles/SearchPage";
import { returnOrgLogos } from "../../Components/ImageSearch/Search";
import { MissingOrgsPopup } from "../../Components/ImageSearch/MissingOrgs/MissingOrgsPopup";
import { RequestMissingLogos } from "../../Components/ImageSearch/RequestOrgs/RequestMissingLogos";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { reset } from "../../Redux/features/filterSlice";
import { createTicketForMissingLogos } from "../../Services/API/missingLogos/createTicketForMissingLogos";
import { resetSelectedOrgs, updateSelectedOrgs } from "../../Redux/features/selectedOrgsSlice";
import { GridOptionsPopup } from "../../Components/GridOptions/GridOptionsPopUp/GridOptionsPopup";
import { hasFeatureGridInsert } from "../../Util/officeAppsAPI";
import FilterPanel from "../../Components/FilterPanel/FilterPanel";
import { findShape } from "../../Components/ImageInsertion/ImageInsertionHelpers";

import {
  findMinimumImageWidth,
  getImageAsBase64String,
  insertImagesFromBase64Strings,
} from "../../Components/ImageInsertion/Images";
import "./Styles/SearchPage.css";
import { Photo } from "../../Components/ImageSearch/ImageSearch.Interfaces";
import { GridDimensions } from "../../Components/ImageInsertion/ImageInsertion.Interfaces";
import RetainShapeValidationErrorPopUp from "../../Components/ImageInsertion/RetainShapeValidationErrorPopUp";
import { UpdateCompressImagesInputStatus } from "../../Redux/features/insertedLogoFormat";
import { UpdateMissingOrgsList } from "../../Redux/features/missingOrgs";

type SearchSource = "wlStore" | "searchEngine";

const { bottomPanelClass, inputWrapper } = getClassNames();

// Spinner during search
function Busy() {
  return (
    <Spinner
      label="Fetching images..."
      ariaLive="assertive"
      labelPosition="top"
      size={SpinnerSize.large}
      className="mt-4"
    />
  );
}

// No logo's found
function NoPhotos({ hasQuery }: { hasQuery: boolean }) {
  return <>{hasQuery && <p>No logos found</p>}</>;
}

export function SearchPage() {
  // Redux
  const dispatch = useAppDispatch();
  const selectedOrgs = useAppSelector((state) => state.Orgs.selectedOrgs);
  const searchFilters = useAppSelector((state) => state.filters.searchFilters);
  const filterCount = useAppSelector((state) => state.filters.searchFilters.count);
  const altCount = useAppSelector((state) => state.filters.searchFilters.altCount);
  const isBest = useAppSelector((state) => state.filters.searchFilters.isBest);

  // Local state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [invalidSearchMessage, setInvalidSearchMessage] = useState<string>("");
  const [showLimitWarning, setShowLimitWarning] = useState<boolean>(false);

  const [requestMissingLogos, setRequestMissingLogos] = useState(false);
  const [isRequestMissingLogosSuccessful, setIsRequestMissingLogosSuccessful] = useState<any>(undefined);
  // const [missingOrgs, setMissingOrgs] = useState<string[]>([]);
  const [showMissingOrgsPopup, setMissingOrgsPopup] = useState<boolean>(false);

  const [photos, setPhotos] = useState<Photo[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<Photo[]>([]);
  const [isAllSelected, setAllSelected] = useState<boolean | undefined>(false);

  const [page, setPage] = useState(1);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [searchQueryPhoto, setSearchQueryPhoto] = useState("");
  const [searchSource, setSearchSource] = useState<SearchSource>("wlStore");
  const [searchTags, setSearchTags] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState([]); // preserve selections in auto-complete during re-renders

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [clearTags, setClearTags] = useState<boolean>(false);

  const [showGridOptions, setShowGridOptions] = useState(false);
  const [isDistributeHorizontallySelected, setDistributeHorizontallySelected] = useState(false);
  const [alignment, setAlignment] = useState<string>("center");
  const [isShapeSelected, setIsShapeSelected] = useState<boolean>(false);
  const [isRetainShapeSelected, setIsRetainShapeSelected] = useState<boolean>(false);
  const [retainShapeValidationErrorMessage, setRetainShapeValidationErrorMessage] = useState<boolean>(false);
  const [retainShapeValidationErrorMessagePopUp, setRetainShapeValidationErrorMessagePopUp] = useState<boolean>(false);
  const isCompressImagesSelected = useAppSelector((state) => state.insertedLogoFormat.logoFormat);
  const missingOrgList = useAppSelector((state) => state.missingOrgsFormatRedux.missingOrgs);

  //currentImageOnTheGrid will hold array of images displayed on the photo grid at the moment
  let currentImageArray: any[];
  useEffect(() => {
    currentImageArray = photos.map((item: any) => {
      return item[0];
    });
    setCurrentImageOnTheGrid(currentImageArray);
  }, [photos]);
  const [currentImageOnTheGrid, setCurrentImageOnTheGrid] = useState<Photo[]>([]);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (selectedPhotos.length === photos.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedPhotos]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
    setSelectedPhotos([]);
    setNextPageLoading(false);
    returnOrgLogos(searchQueryPhoto, page, searchSource, searchFilters).then((data) => {
      // console.log(data.photos,"data.photos")
      if (data.missingOrgs.length > 0) {
        setMissingOrgsPopup(true);
        dispatch(UpdateMissingOrgsList({ missingOrgs: data.missingOrgs }));
      }
      setPhotos(data.photos);
      setIsLastPage(data.isLastPage);
      setLoading(false);
      setAllSelected(false);
    });
  }, [searchQueryPhoto, searchSource]);

  const performSearch = async (query: any) => {
    setError(undefined);
    setInvalidSearchMessage("");
    setPage(1);
    setSelectedPhotos([]);
    setSearchQueryPhoto(query);
  };

  const handleReturnedOrgs = useCallback(
    (e: any) => {
      dispatch(resetSelectedOrgs());
      setClearTags(false);
      // const orgNames = e.map((org: any) => org.name.toString());
      const orgNames = e.map((org: any) => ({
        Key: org.searchFieldName || "",
        Value: org.name.toString(),
      }));

      setSearchTags(orgNames);
      dispatch(updateSelectedOrgs({ NewOrgNames: orgNames }));
      setShowLimitWarning(searchSource === "searchEngine" && orgNames.length === 15 ? true : false);
    },
    [searchSource]
  );

  const handleAppliedFilters = useCallback(() => {
    // const filteredSearchTags = selectedOrgs.filter((item: any) => item.trim() !== "");
    setShowFilters(false);
    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));
    setIsRetainShapeSelected(false);
    performSearch(selectedOrgs);
  }, [selectedOrgs]);

  async function handleSelectPhoto(photo: Photo) {
    const newSelectedOrder = selectedPhotos.slice();
    if (newSelectedOrder.includes(photo)) {
      newSelectedOrder.splice(newSelectedOrder.indexOf(photo), 1);
    } else {
      newSelectedOrder.push(photo);
    }
    setSelectedPhotos(newSelectedOrder);
  }

  const handleSearch = useCallback((searchTags: any) => {
    if (searchTags.length > 0) {
      performSearch(searchTags);
    } else {
      setInvalidSearchMessage("Please provide organization name.");
    }
    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));
    setIsRetainShapeSelected(false);
  }, []);

  async function onChangeSelectAll(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
    if (isChecked) {
      let selectedPhotos = currentImageOnTheGrid.flat();
      setSelectedPhotos(selectedPhotos);
    } else {
      setSelectedPhotos([]);
    }
    setAllSelected(isChecked);
  }

  async function handleMissingLogo(missingOrgs: any) {
    if (missingOrgs.length > 0) {
      await createTicketForMissingLogos(missingOrgs, searchFilters, setIsRequestMissingLogosSuccessful);
    }
  }

  const appendNextPage = () => {
    if (!isNextPageLoading && !isLastPage) {
      setNextPageLoading(true);

      returnOrgLogos(searchQueryPhoto, page + 1, searchSource, searchFilters).then((data) => {
        let newPhotos = [...photos];
        newPhotos = newPhotos.concat(data.photos);
        setAllSelected(false);
        setPhotos(newPhotos);
        setPage(page + 1);
        setIsLastPage(data.isLastPage);
        setNextPageLoading(false);
      });
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const scrollBottomTrigger = 50; // How far from the bottom before the next page load is taken
    const target = e.target as HTMLElement;
    const bottom = target.scrollHeight - target.scrollTop < target.clientHeight + scrollBottomTrigger;
    if (bottom) {
      appendNextPage();
    }
  };

  const resetFilters = useCallback(() => {
    // const filteredSearchTags = selectedOrgs.filter((item: any) => item.trim() !== "");
    setShowFilters(false);
    dispatch(reset());
    setIsRetainShapeSelected(false);
    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));
    performSearch(selectedOrgs);
  }, [selectedOrgs]);

  const HandleClearTags = useCallback(() => {
    setSearchTags([]);
    setClearTags(true);
    dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
    setPhotos([]);
    setShowFilters(false);
    setInvalidSearchMessage("");
    dispatch(resetSelectedOrgs());
    setError(undefined);
  }, []);

  // Image Insertion
  async function addImagesToDocument(photos: Photo[], insertGridDimensions: GridDimensions) {
    console.log(photos, "Photos inside addImagesToDocument");
    try {
      setError(undefined);

      const minImageWidth = await findMinimumImageWidth(insertGridDimensions);
      const base64Images = await Promise.all(
        photos.map((photo) =>
          getImageAsBase64String(
            photo.url,
            minImageWidth,
            photo.orgName,
            photo.isImageAvailable,
            isCompressImagesSelected
          )
        )
      );
      await insertImagesFromBase64Strings(
        base64Images,
        insertGridDimensions,
        alignment,
        isRetainShapeSelected,
        isDistributeHorizontallySelected
      );
      setAlignment("center");
      setDistributeHorizontallySelected(false);
    } catch (err: any) {
      console.error(err);
      setError(`Unable to use image`);
      throw err;
    }
  }

  async function showInsertGrid() {
    let shape;
    let isShapeLine;
    // check if powerpoint
    if (hasFeatureGridInsert()) {
      shape = await findShape();

      if (shape !== null) {
        isShapeLine = shape.name.includes("Straight" && "Connector");
      }
      if (shape === null && isRetainShapeSelected === true) {
        setRetainShapeValidationErrorMessagePopUp(true);
      } else {
        setRetainShapeValidationErrorMessagePopUp(false);

        if (selectedPhotos.length > 1) {
          if (isShapeLine === true) {
            addImagesToDocument(selectedPhotos, { rows: 1, columns: selectedPhotos.length });
          } else {
            setShowGridOptions(true);
          }
        } else {
          addImagesToDocument(selectedPhotos, { rows: 1, columns: 1 });
        }
      }
    } else {
      addImagesToDocument(selectedPhotos, { rows: 1, columns: 1 });
    }
  }
  return (
    <section className="sp-wrapper">
      <>
        {showFilters && (
          <FilterPanel clearFilters={() => resetFilters()} onApplyFilters={() => handleAppliedFilters()} />
        )}

        {!showFilters && (
          <div className="sp-search-wrapper">
            <div className={inputWrapper}>
              <SearchAutoComplete
                selected={currentlySelected}
                setSelected={setCurrentlySelected}
                disabled={loading}
                onSearch={(e: any) => handleReturnedOrgs(e)}
                clearTags={clearTags}
                setInvalidSearchMessage={() => setInvalidSearchMessage("")}
              />
            </div>
            <div className="sp-submit-wrapper">
              <IconButton
                iconProps={{ iconName: "Search" }}
                title="Search"
                aria-label="Search"
                onClick={() => handleSearch(searchTags)}
                disabled={loading}
              />
            </div>
          </div>
        )}

        {invalidSearchMessage.length > 0 ? (
          <MessageBar messageBarType={MessageBarType.error}>{invalidSearchMessage}</MessageBar>
        ) : null}

        <div className="sp-search-links">
          {!showFilters && (
            <Link className="sp-filter-link" onClick={() => HandleClearTags()} underline>
              Clear
            </Link>
          )}

          <Link
            className="sp-filter-link"
            onClick={() => {
              setShowFilters(true);
              setInvalidSearchMessage("");
            }}
            underline
          >
            {`Sort & Filter ${`(${isBest ? altCount.length : filterCount.length})`}`}
          </Link>
        </div>

        {showLimitWarning && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={() => {
              setShowLimitWarning(false);
            }}
            dismissButtonAriaLabel="Close"
          >
            Please search with a maximum of 15 organisation names.
          </MessageBar>
        )}

        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}

        <Pivot
          aria-label="Search type"
          className="sp-pivot"
          selectedKey={searchSource}
          onLinkClick={(item?: PivotItem) => {
            if (item) {
              const searchSource = item.props.itemKey as SearchSource;
              setSearchSource(searchSource);
              setPage(1);
            }
          }}
        >
          {!showFilters && (
            <PivotItem
              headerText="Logos"
              headerButtonProps={{
                "data-order": 1,
                "data-title": "Search WL Logo Store",
                disabled: loading,
                style: { color: loading ? "grey" : "inherit" },
              }}
              itemKey="wlStore"
            />
          )}
        </Pivot>

        {/* Check if search started with no errors */}
        {!error && loading && <Busy />}

        {/* No logo's found after search */}
        {photos.length! === 0 && missingOrgList.length > 0 && !loading ? (
          <NoPhotos hasQuery={searchQueryPhoto !== ""} />
        ) : null}

        {/* Display successfully found logo's into photo grid */}
        {!error && !loading && photos.length !== undefined && photos.length > 0 && !showFilters && (
          <section className="sp-photo-grid" onScroll={handleScroll}>
            <PhotoGrid
              photos={photos}
              selectedPhotos={selectedPhotos}
              handleSelectPhoto={handleSelectPhoto}
              onPhotosPlaced={(moreSpaces) => moreSpaces && appendNextPage()}
              currentImageOnTheGrid={currentImageOnTheGrid}
              setCurrentImageOnTheGrid={() => setCurrentImageOnTheGrid(currentImageOnTheGrid)}
            ></PhotoGrid>
          </section>
        )}

        {showMissingOrgsPopup && (
          <MissingOrgsPopup
            showMissingOrgsPopup={showMissingOrgsPopup}
            setMissingOrgsPopup={setMissingOrgsPopup}
            setRequestMissingLogos={() => setRequestMissingLogos(!requestMissingLogos)}
            handleMissingLogo={() => handleMissingLogo(missingOrgList)}
            missingOrgs={missingOrgList}
          />
        )}

        {requestMissingLogos && (
          <RequestMissingLogos
            requestMissingLogos={requestMissingLogos}
            setRequestMissingLogos={() => setRequestMissingLogos(false)}
            isRequestMissingLogosSuccessful={isRequestMissingLogosSuccessful}
            setIsRequestMissingLogosSuccessful={() => setIsRequestMissingLogosSuccessful(undefined)}
          />
        )}

        {photos.length > 0 && !loading && !showFilters && (
          <section className={bottomPanelClass}>
            <div className="sp-select-all">
              <Checkbox label="Select all" onChange={onChangeSelectAll} checked={isAllSelected} />
            </div>
            <div className="sp-retainShape">
              {hasFeatureGridInsert() ? (
                <Checkbox
                  label="Retain shape"
                  checked={isRetainShapeSelected}
                  onChange={() => setIsRetainShapeSelected(!isRetainShapeSelected)}
                />
              ) : (
                <></>
              )}
            </div>

            <PrimaryButton
              className="sp-insert-btn"
              disabled={!(selectedPhotos.length > 0)}
              onClick={() => showInsertGrid()}
            >
              Insert
            </PrimaryButton>
          </section>
        )}

        {showGridOptions && (
          <GridOptionsPopup
            show={showGridOptions}
            setAlignment={setAlignment}
            alignment={alignment}
            isRetainShapeSelected={isRetainShapeSelected}
            setIsRetainShapeSelected={setIsRetainShapeSelected}
            setIsShapeSelected={setIsShapeSelected}
            retainShapeValidationErrorMessage={retainShapeValidationErrorMessage}
            setRetainShapeValidationErrorMessage={setRetainShapeValidationErrorMessage}
            isShapeSelected={isShapeSelected}
            itemCount={selectedPhotos.length}
            isDistributeHorizontallySelected={isDistributeHorizontallySelected}
            setDistributeHorizontallySelected={setDistributeHorizontallySelected}
            onInsert={(insertGridDimensions: GridDimensions) => {
              addImagesToDocument(selectedPhotos, insertGridDimensions);
              setShowGridOptions(false);
            }}
            onCancel={() => {
              setShowGridOptions(false), setRetainShapeValidationErrorMessage(false);
            }}
          />
        )}
        {retainShapeValidationErrorMessagePopUp && (
          <RetainShapeValidationErrorPopUp
            retainShapeValidationErrorMessagePopUp={retainShapeValidationErrorMessagePopUp}
            setRetainShapeValidationErrorMessagePopUp={setRetainShapeValidationErrorMessagePopUp}
          />
        )}
      </>
    </section>
  );
}

export default SearchPage;
