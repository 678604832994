import React, { useEffect } from "react";
import { typeOptions, formatOptions, opacityOptions, colourOptions, sortOptions, themeOptions } from "../../Util/filterOptions";
import { useAppDispatch } from "../../Redux/store/store";
import { RadioButtonsStyles, dropdownStyles, getClassNames } from "./Styles/FilterPanelStyles";
import { useAppSelector } from "../../Redux/store/store";
import {
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  PrimaryButton,
  ResponsiveMode,
  Toggle,
  IChoiceGroupOption,
  IDropdownOption,
} from "@fluentui/react";
import {
  updateType,
  updateFormat,
  updateOpacity,
  updateColour,
  updateTheme,
  updateIsLatest,
  updateSort,
  updateIsAsc,
  updateIsBest,
  updateIsAlias,
} from "../../Redux/features/filterSlice";
import "./Styles/FilterPanel.css";
import { FilterPanelProps } from "./FilterPanel.Interfaces";
import { UpdateSelectedtabStatus } from "../../Redux/features/autoCompleteSuggestionHeader";

const { topFilters, filterAscending } = getClassNames();

const FilterPanel = ({ clearFilters, onApplyFilters }: FilterPanelProps) => {
  const dispatch = useAppDispatch();
  // const isLatest = useAppSelector((state) => state.filters.searchFilters.isLatest);
  const isBest = useAppSelector((state) => state.filters.searchFilters.isBest);
  const type = useAppSelector((state) => state.filters.searchFilters.type);
  const format = useAppSelector((state) => state.filters.searchFilters.format);
  const opacity = useAppSelector((state) => state.filters.searchFilters.opacity);
  const colour = useAppSelector((state) => state.filters.searchFilters.colour);
  const theme = useAppSelector((state) => state.filters.searchFilters.theme);
  const sort = useAppSelector((state) => state.filters.searchFilters.sort);
  const isAsc = useAppSelector((state) => state.filters.searchFilters.isAsc);
  const isAlias = useAppSelector((state) => state.filters.searchFilters.isAlias);

  useEffect(() => {
    dispatch(UpdateSelectedtabStatus({ selectedTab: isAlias ? "StockSymbol" : "Name" }));
  }, [isAlias]);
  async function onSelectIsAlias() {
    dispatch(updateIsAlias());
  }
  return (
    <div>
      <header className="filters-header">Search Filters</header>
      <div className="filters-top">
        <Toggle
          className={topFilters}
          label="Best"
          checked={isBest}
          onChange={() => {
            dispatch(updateIsBest());
          }}
        />
        <Toggle className={topFilters} label="Alias" checked={isAlias} onChange={() => onSelectIsAlias()} />
      </div>

      <div>
        <ChoiceGroup
          selectedKey={type}
          options={typeOptions}
          styles={RadioButtonsStyles}
          disabled={isBest ? true : false}
          onChange={(_e: any, option: IChoiceGroupOption | undefined) =>
            dispatch(updateType({ newType: option?.key || type }))
          }
          label="Type"
        />
        <ChoiceGroup
          selectedKey={format}
          options={formatOptions}
          styles={RadioButtonsStyles}
          disabled={isBest ? true : false}
          onChange={(_e: any, option: IChoiceGroupOption | undefined) =>
            dispatch(updateFormat({ newFormat: option?.key || format }))
          }
          label="Format"
        />
        <ChoiceGroup
          selectedKey={opacity}
          options={opacityOptions}
          styles={RadioButtonsStyles}
          disabled={isBest ? true : false}
          onChange={(_e: any, option: IChoiceGroupOption | undefined) =>
            dispatch(updateOpacity({ newOpacity: option?.key || opacity }))
          }
          label="Opacity"
        />
        <ChoiceGroup
          selectedKey={colour}
          options={colourOptions}
          styles={RadioButtonsStyles}
          disabled={isBest ? true : false}
          onChange={(_e: any, option: IChoiceGroupOption | undefined) =>
            dispatch(updateColour({ newColor: option?.key || colour }))
          }
          label="Color"
        />
          <ChoiceGroup
          selectedKey={theme}
          options={themeOptions}
          styles={RadioButtonsStyles}
          disabled={isBest ? true : false}
          onChange={(_e: any, option: IChoiceGroupOption | undefined) =>
            dispatch(updateTheme({ newTheme: option?.key || theme }))
          }
          label="Theme"
        />
        <div className="filters-sort-container">
          <Dropdown
            placeholder="Select an option"
            selectedKey={sort}
            options={sortOptions}
            styles={dropdownStyles}
            responsiveMode={ResponsiveMode.large}
            label="Sort"
            onChange={(_e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>) =>
              dispatch(updateSort({ newSort: option?.key.toString() || sort }))
            }
          />
          {sort !== "searchOrder" && (
            <Toggle
              className={filterAscending}
              label="Ascending"
              checked={isAsc}
              onChange={() => dispatch(updateIsAsc())}
            />
          )}
        </div>
        <div className="filters-btns">
          <PrimaryButton onClick={() => onApplyFilters()} text="Apply" />
          <DefaultButton onClick={() => clearFilters()} text="Clear" />
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
